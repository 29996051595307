<template>
  <div class="content">
    <TitleInfo title1="趋势分析" title2="报警趋势"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select v-model="queryForm.deviceTypeID" placeholder="Select">
            <el-option
              v-for="item in typeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入设备名称"
            class="keyInput"
          />
          <div style="margin-left: 10px">存储时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
        </div>
      </div>
      <div class="tableInfo" id="chart1"></div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetHistoryDataPageList } from '@/api/DataRecord'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import * as echarts from 'echarts'
const queryForm = ref({
  deviceTypeID: '',
  dtStart: new Date(),
  dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
  pageIndex: 1,
  pageSize: 10
})
const typeInfo = ref([
  {
    ID: '',
    Name: '全部'
  }
])
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 1)
      return [start, end]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setMonth(start.getMonth() - 3)
      return [start, end]
    }
  }
])
const dateRang = ref([
  new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
  new Date()
])
const total = ref(0)
const tableData = reactive([])
onMounted(() => {
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          typeInfo.value.push({
            ID: item.ID,
            Name: item.Name
          })
        })
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
  const command_edom1 = document.getElementById('chart1')
  command_edom1.removeAttribute('_echarts_instance_')
  chart1 = myEcharts.init(command_edom1, 'purple-passion')
  window.onresize = function () {
    if (chart1 != null) chart1.resize()
  }
  chart1.setOption({
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          color: '#57617B'
        }
      }
    },
    legend: {
      icon: 'rect',
      itemWidth: 14,
      itemHeight: 5,
      itemGap: 13,
      data: ['最高温度', '最低温度'],
      right: '4%',
      textStyle: {
        fontSize: 12,
        color: '#ccc'
      }
    },
    grid: {
      left: '2%',
      right: '2%',
      bottom: '3%',
      top: '10%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          lineStyle: {
            color: '#DDD'
          }
        },
        data: [
          '2019-7-1',
          '2019-7-2',
          '2019-7-3',
          '2019-7-4',
          '2019-7-5',
          '2019-7-6',
          '2019-7-7'
        ]
      }
    ],
    yAxis: [
      {
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: '#DDD'
          }
        },
        axisLabel: {
          margin: 5,
          textStyle: {
            fontSize: 14
          }
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#DDD'
          }
        }
      }
    ],
    series: [
      {
        name: '最高温度',
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          normal: {
            width: 3
          }
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              1,
              0,
              [
                {
                  offset: 0,
                  color: 'rgba(16,97,204, 0.3)'
                },
                {
                  offset: 0.8,
                  color: 'rgba(17,235,210, 0)'
                }
              ],
              false
            ),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          }
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: 'rgba(16,97,204,1)'
              },
              {
                offset: 1,
                color: 'rgba(17,235,210,1)'
              }
            ])
          },
          emphasis: {
            color: 'rgb(0,196,132)',
            borderColor: 'rgba(0,196,132,0.2)',
            extraCssText: 'box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);',
            borderWidth: 10
          }
        },
        data: [32, 34, 39, 35, 38, 36, 34]
      },
      {
        name: '最低温度',
        type: 'line',
        smooth: true,
        symbol: 'circle',
        symbolSize: 5,
        showSymbol: false,
        lineStyle: {
          normal: {
            width: 3
          }
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: 'rgba(205,52,42, 0.5)'
                },
                {
                  offset: 0.8,
                  color: 'rgba(235,235,21, 0)'
                }
              ],
              false
            ),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10
          }
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
              {
                offset: 0,
                color: 'rgba(205,52,42,1)'
              },
              {
                offset: 1,
                color: 'rgba(235,235,21,1)'
              }
            ])
          },
          emphasis: {
            color: 'rgb(99,250,235)',
            borderColor: 'rgba(99,250,235,0.2)',
            extraCssText: 'box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);',
            borderWidth: 10
          }
        },
        data: [25, 22, 26, 28, 27, 26, 23]
      }
    ]
  })
})
onBeforeUnmount(() => {
  chart1.dispose
})
const initGetList = async () => {}
const resetInfo = () => {
  queryForm.value = {
    userID: '',
    dtStart: new Date(),
    dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
let myEcharts = echarts
let chart1
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
